import React from 'react';
import PropTypes from 'prop-types';
import { useClassNames } from '../../utils/Class';

const SidebarFeature = ({ feature, className }) => {
  const { title, description, image } = feature;

  const fullClass = useClassNames('flex flex-col rounded-lg shadow overflow-hidden', className);

  return (
    <div className={fullClass}>
      <div className="flex-shrink-0">
        <img className="h-52 w-full object-cover" src={image.file.url} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <h3 className="text-xl leading-7 font-semibold text-gray-900">
            {title}
          </h3>
          <p className="mt-2 text-base leading-6 text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

SidebarFeature.propTypes = {
  feature: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default SidebarFeature;
