import { useStaticQuery } from 'gatsby';

const PATH_QUERY = graphql`
  query PathQuery {
    contentfulHeaderMenu {
      id
      menuItem {
        id
        label
        sublinks {
          id
          label
          description {
            description
          }
          url
        }
      }
    }
  }
`;

export const trimUrl = (url = '') => {
  return url.replace('/', '');
}

export const usePath = () => {
  if (typeof window !== 'undefined') {
    return trimUrl(window.location.pathname);
  }

  return '';
};

export const usePathInfo = (currentPath) => {
  const { contentfulHeaderMenu: menuData } = useStaticQuery(PATH_QUERY);

  const pathInfo = {
    currentPath
  };

  menuData.menuItem.forEach(menuItem => {
    if (menuItem.sublinks && menuItem.sublinks.length) {
      menuItem.sublinks.forEach(sublink => {
        if (trimUrl(sublink.url) === pathInfo.currentPath) {
          pathInfo.currentMenuItem = menuItem;
          pathInfo.currentLink = sublink;
          return false;
        }
      });
    }
  });

  return pathInfo;
};
