import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import VerticalNav from './VerticalNav';
import SidebarFeature from './SidebarFeature';

const SIDEBAR_QUERY = graphql`
  query SidebarQuery {
    contentfulSidebarFeature {
      title
      description
      image {
        file {
          url
        }
      }
      button {
        label
        url
      }
    }
  }
`;

const Sidebar = ({ pathInfo }) => {
  const { contentfulSidebarFeature: sidebarFeature } = useStaticQuery(SIDEBAR_QUERY);

  return (
    <React.Fragment>
      <div className="w-full p-5 rounded-lg shadow">
        <div className="w-full text-xl pb-2 mb-2 font-medium border-b border-gray-200">
          <p className="text-periwinkle-base">{pathInfo.currentMenuItem.label}</p>
        </div>
        <VerticalNav pathInfo={pathInfo} />
      </div>
      <SidebarFeature className="mt-8" feature={sidebarFeature} />
    </React.Fragment>
  );
};

export default Sidebar;
