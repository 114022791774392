import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../Sidebar/Sidebar';
import PageFeaturesBlock from '../PageFeature/PageFeaturesBlock';
import { usePathInfo } from '../../utils/Url';
import Seo from '../seo';

import Layout from '../layout';

const Page = (props) => {
  const { title, bannerImage } = props.pageContext;
  const pathInfo = usePathInfo(props.pageContext.slug);

  const isNested = pathInfo.currentLink && pathInfo.currentMenuItem;

  return (
    <React.Fragment>
      <Layout>
        <Seo
          title={title}
          description={pathInfo.currentLink ? pathInfo.currentLink.description.description : null}
        />
        {bannerImage && (
          <div
            className="width-full mb-4 relative page-title-banner"
            style={{
              backgroundImage: `url('${bannerImage.file.url}')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <div
              className="absolute top-0 left-0 bottom-0 right-0"
              style={{
                background: 'linear-gradient(150deg, #3F383A, rgba(35, 31, 32, 0))'
              }}
            >
              <div className="mx-auto px-6 h-full flex items-center max-w-screen-xl">
                <div
                  data-sal="fade"
                  data-sal-duration="500"
                  data-sal-delay="0"
                  data-sal-easing="ease-in-out"
                >
                  <h1 className="text-5xl white mb-2 font-bold text-white">
                    {title}
                  </h1>
                  <div style={{ width: '140px', height: '5px' }} className="bg-white mb-6" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mx-auto px-6 py-6 max-w-screen-xl mb-16">
          <div className="grid grid-cols-12 lg:gap-12 width-full">
            <div className={isNested ? 'col-span-12 lg:col-span-9' : 'col-span-12'}>
              {!bannerImage && (
                <React.Fragment>
                  <h1 className="text-5xl mb-2 font-bold text-gray-900">
                    {title}
                  </h1>
                  <div style={{ width: '140px', height: '5px'}} className="bg-periwinkle-base mb-6" />
                </React.Fragment>
              )}
              {props.children}
            </div>
            {isNested && (
              <div className="mb-6 pt-4 lg:mb-0 col-span-12 lg:col-span-3">
                <div className="lg:sticky" style={{ top: 140 }}>
                  <Sidebar pathInfo={pathInfo} />
                </div>
              </div>
            )}
          </div>
          <div className="my-16 bg-gray-100 w-full" style={{ height: '1px' }} />
          <PageFeaturesBlock />
        </div>
      </Layout>
    </React.Fragment>
  );
};

Page.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.shape({
      json: PropTypes.object.isRequired
    })
  }).isRequired
};

export default Page;
