import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

const VerticalNav = ({ pathInfo }) => {
  return (
    <nav className="mt-3">
      {pathInfo.currentMenuItem.sublinks.map(sublink => (
          <Link
            key={sublink.label}
            to={sublink.url}
            className="block border-b border-gray-100 cursor-pointer mt-1 py-2"
          >
            {sublink.label}
          </Link>
      ))}
    </nav>
  );
};

VerticalNav.propTypes = {
  pathInfo: PropTypes.shape({
    currentLink: PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string
      }),
      currentPath: PropTypes.string,
      currentMenuItem: PropTypes.shape({
        label: PropTypes.string.isRequired
      })
    })
  }).isRequired
};

export default VerticalNav;
