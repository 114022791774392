import React from 'react';
import LinkButton from '../Shared/LinkButton';

const PageFeature = ({ pageFeature }) => {
  const { title, description, image, button } = pageFeature;

  return (
    <div className="flex flex-col rounded-lg shadow overflow-hidden">
      <div className="flex-shrink-0">
        <img className="h-52 w-full object-cover" src={image.file.url} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <h3 className="text-xl leading-7 font-semibold text-gray-900">
            {title}
          </h3>
          <p className="mt-2 mb-4 text-base leading-6 text-gray-500">
            {description.description}
          </p>
          <LinkButton.Periwinkle to={button.url} className="mt-2 inline-block">
            {button.label}
          </LinkButton.Periwinkle>
        </div>
      </div>
    </div>
  );
};

export default PageFeature;
