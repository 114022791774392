import React from 'react';
import Page from '../components/Templates/Page';

const PrivacyPolicyPage = ({ pageContext, ...props}) => {
  const context = {
     title: 'Privacy Policy'
  };

  return (
    <Page pageContext={context} {...props}>
      <table className="privacy-table" align="center" border="1" cellpadding="10" cellspacing="1">
        <tbody>
        <tr>
          <td width="15%">
          <h2>FACTS</h2>
          </td>
          <td>
          <h3>WHAT DOES VALLEY CREDIT UNION DO WITH YOUR PERSONAL INFORMATION?</h3>
          </td>
        </tr>
        <tr>
          <td>
          <h3>WHY?</h3>
          </td>
          <td>
          <p>Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do. </p>
          </td>
        </tr>
        <tr>
          <td>
          <h3>WHAT?</h3>
          </td>
          <td>
          <p>The types of personal information we collect and share depend on the product or service you have with us. This information can include:</p>
          <p> • Social Security number and income</p>
          <p> • Account balances and payment history </p>
          <p> • Credit history and credit scores</p>
          <p> When you are no longer our member, we continue to share your information as described in this notice.</p>
          </td>
        </tr>
        <tr>
          <td>
          <h3>HOW?</h3>
          </td>
          <td>
          <p>All financial companies need to share members’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their members’ personal information; the reasons Valley Credit Union chooses to share; and whether you can limit this sharing.</p>
          </td>
        </tr>
        </tbody>
      </table>

      <table className="privacy-table" align="center" border="1" cellpadding="10" cellspacing="1">
        <tbody>
        <tr>
          <td>
          <h3>Reasons we can share your personal information</h3>
          </td>
          <td width="20%">
          <h3>Does Valley Credit Union share?</h3>
          </td>
          <td width="20%">
          <h3>Can you limit this sharing?</h3>
          </td>
        </tr>
        <tr>
          <td>
          <p><strong>For our everyday business purposes </strong>— such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus</p>
          </td>
          <td><strong>YES</strong></td>
          <td><strong>NO</strong></td>
        </tr>
        <tr>
          <td>
          <p><strong>For our marketing purposes</strong> — to offer our products and services to you</p>
          </td>
          <td><strong>YES</strong></td>
          <td><strong>NO</strong></td>
        </tr>
        <tr>
          <td>
          <p><strong>For joint marketing with other financial companies</strong></p>
          </td>
          <td><strong>YES</strong></td>
          <td><strong>NO</strong></td>
        </tr>
        <tr>
          <td>
          <p><strong>For our affiliates’ everyday business purposes</strong> — information about your transactions and experiences</p>
          </td>
          <td><strong>NO</strong></td>
          <td><strong>NO</strong></td>
        </tr>
        <tr>
          <td>
          <p><strong>For our affiliates’ everyday business purposes </strong>— information about your creditworthiness</p>
          </td>
          <td><strong>NO</strong></td>
          <td><strong>NA</strong></td>
        </tr>
        <tr>
          <td>
          <p><strong>For nonaffiliates to market to you</strong></p>
          </td>
          <td><strong>NO</strong></td>
          <td><strong>NO</strong></td>
        </tr>
        </tbody>
      </table>


      <table className="privacy-table" align="center" border="1" cellpadding="10" cellspacing="1">
        <tbody>
        <tr>
          <td>
          <h3>What we do</h3>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td>
          <p><strong>How does Valley Credit Union protect my personal information?</strong></p>
          </td>
          <td>
          <p>To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</p>
          </td>
        </tr>
        <tr>
          <td>
          <p><strong>How does Valley Credit Union collect my </strong></p>
          <p><strong>personal information?</strong></p>
          </td>
          <td>
          <p>We collect your personal information, for example, when you</p>
          <p>• Open an account or deposit money </p>
          <p>• Pay your bills or apply for a loan</p>
          <p>• Use your credit or debit card</p>
          <p>We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.</p>
          </td>
        </tr>
        <tr>
          <td>
          <p><strong>Why can’t I limit all sharing?</strong></p>
          </td>
          <td>
          <p>Federal law gives you the right to limit only</p>
          <p>• Sharing for affiliates’ everyday business purposes—information about your creditworthiness</p>
          <p>• Affiliates from using your information to market to you</p>
          <p>• Sharing for nonaffiliates to market to you</p>
          <p>State laws and individual companies may give you additional rights to limit sharing.</p>
          </td>
        </tr>
        </tbody>
      </table>

      <table className="privacy-table" align="center" border="1" cellpadding="10" cellspacing="1">
        <tbody>
        <tr>
          <td>
          <h3>Definitions</h3>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td><strong>Affiliates</strong></td>
          <td>
          <p>Companies related by common ownership or control. They can be financial and nonfinancial companies.</p>
          <p>• The Credit Union does not share with affiliates.</p>
          </td>
        </tr>
        <tr>
          <td><strong>Nonaffiliates</strong></td>
          <td>
          <p>Companies not related by common ownership or control. They can be financial and nonfinancial companies.</p>
          <p>• The Credit Union does not share with non-affiliates.</p>
          </td>
        </tr>
        <tr>
          <td><strong>Joint Marketing</strong></td>
          <td>
          <p>A formal agreement between nonaffiliated financial companies that</p>
          <p>together market financial products or services to you.</p>
          <p>• Financial companies include such companies as CUNA Mutual</p>
          </td>
        </tr>
        <tr>
          <td>
          <h3>To limit our sharing</h3>
          </td>
          <td>
          <h3>• Please call 256-381-4800</h3>
          <p>Please Note:
            If you are a new member, we can begin sharing your information 7 days from the date we sent this notice. When you are no longer our member, we continue to share your information as described in this notice.
            However, you can contact us at any time to limit our sharing.
          </p>
          </td>
        </tr>
        <tr>
          <td>
          <h3>Questions?</h3>
          </td>
          <td>
          <h3>Call 256-381-4800</h3>
          </td>
        </tr>
        </tbody>
      </table>
    </Page>
  );
};

export default PrivacyPolicyPage;
