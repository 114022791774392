import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { useClassNames } from "../../utils/Class"
import PageFeature from "./PageFeature"

const PAGE_FEATURE_QUERY = graphql`
  query PageFeaturesQuery {
    contentfulPageFeatures {
      features {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
        button {
          label
          url
        }
      }
    }
  }
`

const PageFeaturesBlock = ({ className }) => {
  const fullClassName = useClassNames(
    "grid grid-cols-12 lg:gap-8 mt-8 lg:mt-16",
    className
  )
  const { contentfulPageFeatures: pageFeatures } = useStaticQuery(
    PAGE_FEATURE_QUERY
  )

  return (
    <div
      className={fullClassName}
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="0"
      data-sal-easing="ease"
    >
      {pageFeatures.features.map(feature => (
        <div
          key={feature.title}
          className="col-span-12 mb-8 lg:mb-0 lg:col-span-6"
        >
          <PageFeature pageFeature={feature} />
        </div>
      ))}
    </div>
  )
}

PageFeaturesBlock.propTypes = {
  className: PropTypes.string,
}

export default PageFeaturesBlock
